<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="footer-logo">
          <img class="logo-img" src="assets/home/images/Pay2Local.png" alt="" />
        </div>
        <p class="slogan">
          Secure and Easy Payment Solutions for Your Business
        </p>
        <div class="social">
          <a href="javascript:void(0)"
            ><i class="fa-brands fa-facebook-f"></i
          ></a>
          <a href="javascript:void(0)"
            ><i class="fa-brands fa-instagram"></i
          ></a>
          <a href="javascript:void(0)"
            ><i class="fa-brands fa-x-twitter"></i
          ></a>
          <a href="javascript:void(0)"
            ><i class="fa-brands fa-linkedin-in"></i
          ></a>
        </div>
      </div>

      <div class="col-md-4">
        <ul class="footer_menu">
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="#Service">Services</a>
          </li>
          <li><a href="javascript:void(0)">Term of Use</a></li>
          <li><a href="javascript:void(0)">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="col-md-4">
        <div class="map">
          <h6>
            M5, Liberty Building, 18th service road, Al Garhoud, Dubai, U.A.E
          </h6>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d48561.423858246264!2d55.2808003508994!3d25.225153338762613!3m2!1i1024!2i768!4f13.1!2m1!1sM5%2C%20Liberty%20Building%2C%2018th%20service%20road%2C%20Al%20Garhoud%2C%20Dubai%2C%20U.A.E!5e0!3m2!1sen!2sin!4v1725703357232!5m2!1sen!2sin"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>Copyright © 2024 Pay2Local. All rights reserved.</p>
    </div>
  </div>
</footer>
<script
  type="text/javascript"
  src="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.min.js"
></script>

<div class="data" #counterSection>
  <div class="inner">
    <h3>
      <span *ngFor="let digit of agentsDigits" class="odometer-digit">{{
        digit
      }}</span
      >+
    </h3>
    <p>Agents</p>
  </div>
  <div class="inner">
    <h3>
      <span *ngFor="let digit of merchantsDigits" class="odometer-digit">{{
        digit
      }}</span
      >+
    </h3>
    <p>Merchants</p>
  </div>
  <div class="inner">
    <h3>
      <span *ngFor="let digit of transactionsDigits" class="odometer-digit">{{
        digit
      }}</span
      >+
    </h3>
    <p>Transactions</p>
  </div>
  <div class="inner">
    <h3>
      <span *ngFor="let digit of successRateDigits" class="odometer-digit">{{
        digit
      }}</span
      >%
    </h3>
    <p>Success</p>
  </div>
</div>

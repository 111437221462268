<div class="home-page">
  <!-- <div id="darkModeToggleBtn">
    <span><i class="fa-regular fa-moon"></i></span>
  </div> -->
  <app-home-header></app-home-header>

  <section class="home-banner">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7">
            <div class="banner-content">
              <h1>
                Instant Payment Solutions Customized to Meet Your Business
                Requirements
              </h1>
              <p>
                Optimize Transactions, Simplify Payouts, and Effectively Handle
                Your Financial Operations Online with Pay2local's Dynamic APIs
                and Merchant Dashboard.
              </p>
              <div class="hm_btn">
                <a href="/contact-us">Connect With Our Team</a>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="globe">
              <app-globe></app-globe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about" class="about_us">
    <div class="container">
      <div class="content">
        <p class="title">About Us</p>
        <h2 data-aos="zoom-in">
          Experience the difference firsthand by choosing us for all your
          payment service needs!
        </h2>
        <p class="description" data-aos="zoom-in" data-aos-delay="300">
          Pay2Local is a payment service provider that specializes in the
          various reliable methods of handling online payments from businesses
          all over the globe.
        </p>
        <p class="description" data-aos="zoom-in" data-aos-delay="300">
          We have covered different types of payments and currencies to provide
          customers with ease-at-no-cost experience.
        </p>
      </div>

      <app-data></app-data>
    </div>
  </section>

  <section id="Key_Features" class="advatages">
    <div class="container">
      <div class="head-content">
        <h2>Key Features</h2>
      </div>
      <div class="divider">
        <div class="border"></div>
        <img src="assets/home/images/light.svg" alt="" />
      </div>
      <div class="slider-arrows">
        <button type="button" class="slider-prev">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <button type="button" class="slider-next">
          <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      <div class="row slider">
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper">
            <img class="one" src="assets/home/images/thumb.gif" alt="" />
            <h3>Easy Payment Form</h3>
            <p>
              The payment form is extremely user-friendly, requiring minimal
              information.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper">
            <img src="assets/home/images/clock.gif" alt="" />
            <h3>24/7 Support & service</h3>
            <p>
              We operate 24/7 and promptly resolve any issues that may arise.
              Your satisfaction as a client is highly valued by us.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper">
            <img src="assets/home/images/setting.gif" alt="" />
            <h3>Effortless Integrate</h3>
            <p>It’s very effortless to integrate Pay2local.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="content-wrapper">
            <img src="assets/home/images/rates.gif" alt="" />
            <h3>Best Rates</h3>
            <p>
              We provide some of the most attractive and competitive rates
              available in the market.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section id="Service" class="payment-options">
    <div class="container">
      <div class="head">
        <p class="title">Services</p>
        <h2>Increase Your Revenue and Accelerate Business Growth</h2>
      </div>
    </div>
    <div class="container">
      <div class="content">
        <div class="bank-slider row">
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/upay.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/nagad.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/rocket.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/bkash.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/phonepe.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/gpay.png" alt="" />
            </div>
          </div>

          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/paytm.png" alt="" />
            </div>
          </div>
          <div class="col-xl-2 col-md-3 col-4">
            <div class="payment-option">
              <img src="assets/img/bharat-pay.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="payment-sss" id="Service">
    <div class="container">
      <div class="services-section">
        <p class="title">Services</p>
        <h2>Increase Your Revenue and Accelerate Business Growth</h2>
        <p class="description">
          Simplify your transactions with our platform, offering comprehensive
          support for numerous payment methods.
        </p>
      </div>

      <div class="globel-optn">
        <div class="payment-grid slider-countries">
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/Bangladesh.png"
                alt="Bangladesh"
                class="avatar"
              />
              <h3>Bangladesh</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/img/upay.png" alt="" />
                  <h4>Upay</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/nagad.png" alt="" />
                  <h4>Nagad</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/rocket.png" alt="" />
                  <h4>Rocket</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/bkash.png" alt="" />
                  <h4>Bkash</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/India.png"
                alt="India"
                class="avatar"
              />
              <h3>India</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/img/phonepe.png" alt="" />
                  <h4>PhonePe</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/gpay.png" alt="" />
                  <h4>Gpay</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/paytm.png" alt="" />
                  <h4>Paytm</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/bharat-pay.png" alt="" />
                  <h4>BharatPe</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/Pakistan.png"
                alt="Pakistan"
                class="avatar"
              />
              <h3>Pakistan</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/home/images/easypaisa.png" alt="" />
                  <h4>easypaisa</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/jazz-cash.jpeg" alt="" />
                  <h4>Jazz Cash</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/sadapay.png" alt="" />
                  <h4>SadaPay</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/upaisa.jpeg" alt="" />
                  <h4>Upaisa</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/Egypt.png"
                alt="Egypt"
                class="avatar"
              />
              <h3>Egypt</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/home/images/etisalat-cash.jpeg" alt="" />
                  <h4>Etisalat Cash</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/fawry-pay.jpeg" alt="" />
                  <h4>Fawry Pay</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/orange-money.png" alt="" />
                  <h4>Orange Money</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/vodafone.png" alt="" />
                  <h4>Vodafone</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="Our_Approach" class="globel-coverage">
    <div class="container">
      <div class="head-content">
        <h2>Our Global Reach</h2>
      </div>
      <div class="all_countries">
        <div class="countries">
          <h3>Operating Countries</h3>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Bangladesh.png" alt="" />
              <h4>Bangladesh</h4>
            </div>
            <div class="domain">
              <a href="">
                <h5>pay<span>2</span>bd</h5>
              </a>
            </div>
          </div>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/India.png" alt="" />
              <h4>India</h4>
            </div>
            <div class="domain">
              <a href="">
                <h5>pay<span>2</span>in</h5>
              </a>
            </div>
          </div>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Pakistan.png" alt="" />
              <h4>Pakistan</h4>
            </div>
            <div class="domain">
              <a href="">
                <h5>pay<span>2</span>pk</h5>
              </a>
            </div>
          </div>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Egypt.png" alt="" />
              <h4>Egypt</h4>
            </div>
            <div class="domain">
              <a href="">
                <h5>pay<span>2</span>eg</h5>
              </a>
            </div>
          </div>
        </div>
        <div class="countries">
          <h3>Upcoming Countries</h3>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Nepal.png" alt="" />
              <a href="">
                <h4>Nepal</h4>
              </a>
            </div>
            <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
          </div>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Sri-Lanka.png" alt="" />
              <a href="">
                <h4>Sri Lanka</h4>
              </a>
            </div>
            <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
          </div>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Vietnam.png" alt="" />
              <a href="">
                <h4>Vietnam</h4>
              </a>
            </div>
            <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
          </div>
          <div class="country">
            <div class="wrapper">
              <img src="assets/home/images/Indonesia.png" alt="" />
              <a href="">
                <h4>Indonesia</h4>
              </a>
            </div>
            <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
          </div>
        </div>
        <div class="countries">
          <h3>Request Countries</h3>
          <div class="country">
            <a href="">
              <div class="wrapper">
                <img src="assets/home/images/African-Countries.png" alt="" />
                <h4>African Countries</h4>
              </div>
              <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
            </a>
          </div>
          <div class="country">
            <a href="">
              <div class="wrapper">
                <img
                  src="assets/home/images/Latin-American-Countries.png"
                  alt=""
                />
                <h4>Latin American Countries</h4>
              </div>
              <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
            </a>
          </div>
          <div class="country">
            <a href="">
              <div class="wrapper">
                <img src="assets/home/images/Others.png" alt="" />
                <h4>Others</h4>
              </div>
              <!-- <div class="domain">
                <h5>Pay2BD.com</h5>
              </div> -->
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="testimonials">
    <div class="head">
      <div class="container">
        <p class="title">Testimonials</p>
        <h2>What People Are Saying</h2>
      </div>
    </div>
    <div class="wrapper">
      <div class="container">
        <div class="slider-arrows">
          <button type="button" class="slick-prev">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <button type="button" class="slick-next">
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div>
        <div class="slider-reviews">
          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-1.png" alt="" />
                <div class="name">
                  <h3>Ishaan Singh</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
              <p>
                Pay2Local has completely transformed the way I handle my
                business transactions. The platform is incredibly user-friendly,
                and the payments are processed quickly without any hassles. It’s
                reliable and secure—everything you need in a payment solution!
              </p>
            </div>
          </div>
          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-2.png" alt="" />
                <div class="name">
                  <h3>Mia Clark</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half"></i>
                  </div>
                </div>
              </div>
              <p>
                Pay2Local offers a smooth payment experience with fast
                processing times. It's reliable, secure, and perfect for
                business needs.
              </p>
            </div>
          </div>

          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-3.png" alt="" />
                <div class="name">
                  <h3>Omar El-Sayed</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
              <p>
                I’ve been using Pay2Local for several months now, and I’m
                impressed with their seamless service. What I love most is their
                responsive customer support team. They are always available to
                assist with any queries. Highly recommend Pay2Local for small
                businesses!
              </p>
            </div>
          </div>
          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-4.png" alt="" />
                <div class="name">
                  <h3>Michael Walker</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
              <p>
                Pay2Local’s affordable fees and efficient service make it my
                go-to payment platform. Highly recommended for businesses
                looking to streamline transactions!
              </p>
            </div>
          </div>
          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-5.png" alt="" />
                <div class="name">
                  <h3>Rehan Ahmed</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half"></i>
                  </div>
                </div>
              </div>
              <p>
                Security is my top priority, and Pay2Local does not disappoint.
                The encryption and safety measures in place give me peace of
                mind knowing that my transactions are secure. It’s a trustworthy
                platform that I will continue using for my payments.
              </p>
            </div>
          </div>
          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-6.png" alt="" />
                <div class="name">
                  <h3>Bilal Qureshi</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
              <p>
                Integrating Pay2Local with my business was incredibly simple.
                The setup was quick, and I was able to start processing payments
                almost immediately. The user interface is intuitive and
                straightforward—perfect for both beginners and experienced
                users.
              </p>
            </div>
          </div>
          <div class="review">
            <div class="review-wrapper">
              <div class="info">
                <img src="assets/home/images/emoji-7.png" alt="" />
                <div class="name">
                  <h3>Tanuj Sharma</h3>
                  <div class="rating">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
              <p>
                I’ve tried various payment platforms, but Pay2Local stands out
                for its focus on local businesses. The fees are affordable, and
                the platform makes it easy to manage transactions efficiently.
                This is definitely a must-have tool for any local business.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-home-footer></app-home-footer>

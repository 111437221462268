<div class="our-story-menu">
  <app-home-header></app-home-header>
</div>

<div class="our-story-page">
  <section class="story-banner">
    <div class="wrapper">
      <div class="container">
        <h1>
          We embarked on our journey in the payment industry three years ago,
          and with 2 million transactions completed, Pay2Local is emerging as a
          leader in the field.
        </h1>
        <app-data></app-data>
      </div>
    </div>
  </section>

  <section class="story">
    <div class="container">
      <div>
        <div class="story-wrapper one">
          <div class="image">
            <img src="assets/img/news-3.jpg" alt="" />
          </div>
          <div class="content">
            <h2>
              We are a payment service provider dedicated to offering various
              dependable methods for managing online payments from businesses
              worldwide.
            </h2>
          </div>
        </div>
        <div class="story-wrapper two">
          <div class="content">
            <h2>
              We cater to a variety of payment types and currencies, ensuring a
              seamless and secure experience for our customers. Our
              user-friendly interface makes it easy for businesses to integrate
              our payment services into their online platforms seamlessly.
            </h2>
          </div>
          <div class="image">
            <img src="assets/img/easy.png" alt="" />
          </div>
        </div>
        <div class="story-wrapper three">
          <div class="image">
            <img src="assets/img/customer-support.png" alt="" />
          </div>
          <div class="content">
            <h2>
              Our dedicated customer support team, from 3 different continents
              is available around the clock to assist with any payment-related
              issues or queries.
            </h2>
          </div>
        </div>
        <div class="story-wrapper four">
          <div class="content">
            <h2>
              Experience the convenience and reliability of our payment services
              by partnering with Pay2Local today.
            </h2>
          </div>
          <div class="image">
            <img src="assets/img/handshake-partners.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-home-footer></app-home-footer>

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import "slick-carousel/slick/slick";

declare var $: any;

declare const require: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements AfterViewInit {
  activeClass: string = "";
  activeId: string | null = null;

  isClassAdded: boolean = false;

  constructor(private el: ElementRef) {}
  @HostListener("window:scroll", [])
  onScroll(): void {
    const offset = this.el.nativeElement.getBoundingClientRect().top;
    if (offset < 0) {
      this.activeClass = "active";
    } else {
      this.activeClass = "";
    }
  }

  showMenu() {
    this.isClassAdded = !this.isClassAdded;
  }

  scrollToSection(sectionId: string) {
    this.showMenu();
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 60; // Offset by 100px
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const offsetPosition = elementRect - bodyRect - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // Smooth scrolling
      });
    }
  }

  ngOnInit(): void {
    // Import AOS using require
    const AOS = require("aos");
    AOS.init({
      duration: 1200, // Duration of the animation
      once: true, // Animation should happen only once
    });
  }

  ngAfterViewInit(): void {
    $(this.el.nativeElement)
      .find(".slider")
      .slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        // slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        autoplaySpeed: 2000,
        draggable: true,
        swipeToSlide: true,
        touchThreshold: 10,
        prevArrow: $(".slider-prev"),
        nextArrow: $(".slider-next"),
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this.el.nativeElement)
      .find(".bank-slider")
      .slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this.el.nativeElement)
      .find(".slider-reviews")
      .slick({
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        // slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: $(".slick-prev"),
        nextArrow: $(".slick-next"),
        draggable: true,
        swipeToSlide: true,
        touchThreshold: 10,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    $(this.el.nativeElement)
      .find(".slider-countries")
      .slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 3,
        // slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        // prevArrow: $(".slick-prev"),
        // nextArrow: $(".slick-next"),
        cssEase: "linear",
        draggable: true,
        swipeToSlide: true,
        touchThreshold: 10,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  }
}

// Wait for the DOM to be ready
document?.addEventListener("DOMContentLoaded", () => {
  // Get the dark mode toggle button and body element
  const darkModeToggleBtn: HTMLButtonElement = document.getElementById(
    "darkModeToggleBtn"
  ) as HTMLButtonElement;
  const body: HTMLElement = document.body;

  // Check if dark mode is enabled in local storage
  if (localStorage.getItem("darkMode") === "enabled") {
    body.classList.add("dark-mode");
  }

  // Toggle dark mode class and update local storage on button click
  darkModeToggleBtn?.addEventListener("click", () => {
    body.classList.toggle("dark-mode");
    // Check if dark mode is enabled and update local storage
    if (body.classList.contains("dark-mode")) {
      localStorage.setItem("darkMode", "enabled");
    } else {
      localStorage.setItem("darkMode", "disabled");
    }
  });
});

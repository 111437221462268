<div class="services-menu">
  <app-home-header></app-home-header>
</div>

<div class="services-page">
  <section class="home-banner">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7">
            <div class="banner-content">
              <h1>
                At Pay2Local, we empower businesses by providing them with
                reliable and secure online payment solutions.
              </h1>
              <p>
                We bridge the gap between consumers and merchants with our Peer
                to Peer (P2P) and Peer to Consumer (P2C) transactions. Our goal
                is to streamline your transactions via our platform and support
                you in accelerating your business growth.
              </p>
            </div>
          </div>
          <div class="col-md-5">
            <div class="video">
              <video
                #videoPlayer
                width="300"
                autoplay
                loop
                muted
                playsinline
                preload="auto"
              >
                <source
                  src="assets/home/images/Pay2Local.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="payment-sss">
    <div class="container">
      <div class="services-section">
        <h2>
          We currently offer our services in Bangladesh, Egypt, India, and
          Pakistan, where our payment solutions integrate seamlessly with local
          payment applications.
        </h2>
      </div>

      <div class="globel-optn">
        <div class="payment-grid slider-countries">
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/Bangladesh.png"
                alt="Bangladesh"
                class="avatar"
              />
              <h3>Bangladesh</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/img/upay.png" alt="" />
                  <h4>Upay</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/nagad.png" alt="" />
                  <h4>Nagad</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/rocket.png" alt="" />
                  <h4>Rocket</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/bkash.png" alt="" />
                  <h4>Bkash</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/India.png"
                alt="India"
                class="avatar"
              />
              <h3>India</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/img/phonepe.png" alt="" />
                  <h4>PhonePe</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/gpay.png" alt="" />
                  <h4>Gpay</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/paytm.png" alt="" />
                  <h4>Paytm</h4>
                </div>
                <div class="bank">
                  <img src="assets/img/bharat-pay.png" alt="" />
                  <h4>BharatPe</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/Pakistan.png"
                alt="Pakistan"
                class="avatar"
              />
              <h3>Pakistan</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/home/images/easypaisa.png" alt="" />
                  <h4>easypaisa</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/jazz-cash.jpeg" alt="" />
                  <h4>Jazz Cash</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/sadapay.png" alt="" />
                  <h4>SadaPay</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/upaisa.jpeg" alt="" />
                  <h4>Upaisa</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="wrapper">
              <img
                src="assets/home/images/Egypt.png"
                alt="Egypt"
                class="avatar"
              />
              <h3>Egypt</h3>
              <div class="banks">
                <div class="bank">
                  <img src="assets/home/images/etisalat-cash.jpeg" alt="" />
                  <h4>Etisalat Cash</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/fawry-pay.jpeg" alt="" />
                  <h4>Fawry Pay</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/orange-money.png" alt="" />
                  <h4>Orange Money</h4>
                </div>
                <div class="bank">
                  <img src="assets/home/images/vodafone.png" alt="" />
                  <h4>Vodafone</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="upcoming-countries">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-6">
          <div class="content">
            <h2>Upcoming <span>Countries</span></h2>
            <p>
              Expanding our services to new territories is an exciting endeavor
              that opens up a world of opportunities. As we prepare to launch in
              Indonesia, Nepal, Sri Lanka, and Vietnam, we are committed to
              providing exceptional service and creating meaningful impact in
              these regions.
            </p>
            <p>
              We also provide our services and support upon request to various
              countries throughout South America and Africa.
            </p>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="countries-img">
            <div class="countries">
              <div class="country">
                <img src="assets/img/india-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/bangladesh-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/pakistan-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/Egypt-flag.png" alt="" />
              </div>
            </div>

            <div class="countries mid">
              <div class="country">
                <img src="assets/img/nepal-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/indonesia.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/Vietnam.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/srilanka-flag.png" alt="" />
              </div>
            </div>

            <div class="countries">
              <div class="country">
                <img src="assets/img/india-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/bangladesh-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/pakistan-flag.png" alt="" />
              </div>
              <div class="country">
                <img src="assets/img/Egypt-flag.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-home-footer></app-home-footer>

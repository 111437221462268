import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-data",
  templateUrl: "./data.component.html",
  styleUrl: "./data.component.css",
})
export class DataComponent {
  @ViewChild("counterSection", { static: true }) counterSection!: ElementRef;

  agentsStart: number = 0;
  merchantsStart: number = 0;
  transactionsStart: number = 0;
  successRateStart: number = 0;

  agentsTarget: number = 100;
  merchantsTarget: number = 11;
  transactionsTarget: number = 2000000;
  successRateTarget: number = 98.95;

  agentsDigits: (string | number)[] = [];
  merchantsDigits: (string | number)[] = [];
  transactionsDigits: (string | number)[] = [];
  successRateDigits: (string | number)[] = [];

  observer: IntersectionObserver | undefined;

  ngOnInit(): void {
    this.createObserver();
    // window.location.href = "/admin";
  }

  createObserver() {
    const options = {
      root: null, // Use the viewport as the root
      threshold: 0.5, // Trigger when 50% of the section is visible
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start the odometer counter once the section is visible
          this.startOdometerCounter(
            "agents",
            this.agentsStart,
            this.agentsTarget
          );
          this.startOdometerCounter(
            "merchants",
            this.merchantsStart,
            this.merchantsTarget
          );
          this.startOdometerCounter(
            "transactions",
            this.transactionsStart,
            this.transactionsTarget
          );
          this.startOdometerCounter(
            "successRate",
            this.successRateStart,
            this.successRateTarget
          );
          observer.unobserve(entry.target); // Stop observing after animation starts
        }
      });
    }, options);

    if (this.counterSection) {
      this.observer.observe(this.counterSection.nativeElement);
    }
  }

  startOdometerCounter(counterType: string, start: number, target: number) {
    const step = Math.ceil(target / 100); // Number of steps in animation
    const interval = setInterval(() => {
      if (start < target) {
        start += step;
        if (start > target) {
          start = target;
        }
        this.updateDigits(counterType, start);
      } else {
        clearInterval(interval); // Stop when target is reached
      }
    }, 30); // Adjust the speed of the animation here
  }

  updateDigits(counterType: string, value: number) {
    switch (counterType) {
      case "agents":
        this.agentsDigits = this.formatNumber(value).split("");
        break;
      case "merchants":
        this.merchantsDigits = this.formatNumber(value).split("");
        break;
      case "transactions":
        this.transactionsDigits = this.formatNumber(value).split("");
        break;
      case "successRate":
        this.successRateDigits = this.formatNumber(value).split("");
        break;
    }
  }

  formatNumber(num: number): string {
    if (num >= 1000000) {
      return Math.floor(num / 1000000) + "M"; // Remove decimals for millions
    } else if (num >= 1000) {
      return Math.floor(num / 1000) + "K"; // Remove decimals for thousands
    }
    return Math.floor(num).toString(); // For small numbers, return whole numbers
  }
}

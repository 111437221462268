import { Component, OnInit, OnDestroy } from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: "app-globe",
  templateUrl: "./globe.component.html",
  styleUrl: "./globe.component.css",
})
export class GlobeComponent implements OnInit, OnDestroy {
  private chart: am4maps.MapChart | undefined;

  ngOnInit(): void {
    // Apply amCharts theme
    am4core.useTheme(am4themes_animated);

    // Create the map chart
    let chart = am4core.create("chartdiv", am4maps.MapChart);

    chart.logo.disabled = true;

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Orthographic();
    chart.panBehavior = "rotateLongLat";
    chart.deltaLatitude = -20;
    chart.padding(20, 20, 20, 20);

    // Limit vertical rotation
    chart.adapter.add("deltaLatitude", function (deltaLatitude) {
      return am4core.math.fitToRange(deltaLatitude ?? 0, -90, 90);
    });

    // Create map polygon series (countries)
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}";
    polygonTemplate.fill = am4core.color("#006a4e"); // Default country color
    polygonTemplate.stroke = am4core.color("#ea2839");
    polygonTemplate.strokeWidth = 0.5;

    // Apply color to pinned countries based on imageSeries data
    polygonTemplate.adapter.add("fill", function (fill, target) {
      // Ensure target.dataItem and target.dataItem.dataContext are defined
      if (target.dataItem && target.dataItem.dataContext) {
        const dataContext = target.dataItem.dataContext as { name: string };

        // Find the country by name in the imageSeries data
        let country = imageSeries.data.find(
          (item: any) => item.title === dataContext.name
        );

        // If a country is found and has a color, apply it
        if (country && country.color) {
          return country.color;
        }
      }

      // Return the default fill color if no match
      return fill;
    });

    // Create map image series (pins)
    let imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.mapImages.template.propertyFields.longitude = "longitude";
    imageSeries.mapImages.template.propertyFields.latitude = "latitude";
    imageSeries.mapImages.template.tooltipText = "{title}";
    imageSeries.mapImages.template.propertyFields.url = "url";

    // Create marker icon (pin)
    let circle = imageSeries.mapImages.template.createChild(am4core.Image);
    circle.href = "./assets/home/images/Location.svg"; // Add path to your pin image here
    circle.width = 40;
    circle.height = 40;
    circle.horizontalCenter = "middle";
    circle.verticalCenter = "bottom";

    // Add country data with specific colors
    imageSeries.data = [
      {
        title: "India",
        latitude: 20.5937,
        longitude: 78.9629,
        color: am4core.color("#25a244"), // Red for India
      },
      {
        title: "Bangladesh",
        latitude: 23.685,
        longitude: 90.3563,
        color: am4core.color("#25a244"), // Green for Bangladesh
      },
      {
        title: "Pakistan",
        latitude: 30.3753,
        longitude: 69.3451,
        color: am4core.color("#25a244"), // Blue for Pakistan
      },
      {
        title: "Egypt",
        latitude: 26.8206,
        longitude: 30.8027,
        color: am4core.color("#25a244"), // Yellow for Egypt
      },
    ];

    // Add graticule lines (latitude/longitude lines)
    let graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
    graticuleSeries.mapLines.template.line.stroke = am4core.color("#112c53");
    graticuleSeries.mapLines.template.line.strokeOpacity = 0.08;
    graticuleSeries.fitExtent = false;

    // Background settings
    chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0.1;
    chart.backgroundSeries.mapPolygons.template.polygon.fill =
      am4core.color("#000");

    // Create hover state for countries
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = chart.colors.getIndex(0).brighten(-0.5);

    // Animation on the map's rotation
    let animation: any;
    setTimeout(function () {
      animation = chart.animate(
        { property: "deltaLongitude", to: 100000 },
        20000000
      );
    }, 100);

    chart.seriesContainer.events.on("down", function () {
      if (animation) {
        animation.stop();
      }
    });

    // Store chart instance for later disposal
    this.chart = chart;
  }

  // Dispose the chart when the component is destroyed
  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.dispose();
    }
  }
}

import { Component, ElementRef, ViewEncapsulation } from "@angular/core";
import "slick-carousel/slick/slick";

declare var $: any;

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrl: "./services.component.css",
  encapsulation: ViewEncapsulation.None,
})
export class ServicesComponent {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    $(this.el.nativeElement)
      .find(".slider-countries")
      .slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        draggable: true,
        swipe: true,
        swipeToSlide: true,
        touchThreshold: 100,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

    $(this.el.nativeElement).find(".countries").slick({
      dots: false,
      arrows: false,
      infinite: true,
      speed: 3000,
      slidesToShow: 3,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      draggable: true,
      swipe: true,
      swipeToSlide: true,
      touchThreshold: 100,
    });
  }
}

<div class="contact-menu">
  <app-home-header></app-home-header>
</div>
<div class="contact-page">
  <section class="contact-banner">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="content">
            <h1>
              Contact Us at Pay2Local for Personalized Assistance with Payment
              Solutions.
            </h1>
            <p>
              We’re here to support you with all your payment solutions needs.
              Whether you’re seeking information about our services, need
              technical support, or wish to discuss a potential partnership, our
              team is ready to assist you.
            </p>
          </div>
        </div>
        <div class="col-md-5">
          <div class="image">
            <img src="assets/home/images/support.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="form-section">
    <div class="container-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="contact-info">
              <h2>Connect With Us:</h2>
              <p>
                Self are here, looking forward for a possibility to explain you
                how Pay2Local can improve your business with the help of
                improved payments services. If there is any question one want an
                answer to, or any assistance that one may need from the
                organization to be a member or otherwise, we are here. Please
                come to us today, we would be delighted to listen to you on how
                you would wish to be assisted in achieving your goals. Contact
                us through the contact form or email or via social networks and
                we will open the gate to harmonic payment methods.
              </p>
              <ul>
                <li>
                  <p>
                    <a href="mailto:info@pay2local.com"
                      ><i class="fa-regular fa-envelope"></i>
                      <div class="id">
                        <h3>E-mail</h3>
                        <p>{{ "info@pay2local.com" }}</p>
                      </div>
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="https://t.me/pay2local" target="_blank"
                      ><i class="fa-brands fa-telegram"></i>
                      <div class="id">
                        <h3>Telegram</h3>
                        <p>{{ "@pay2local" }}</p>
                      </div>
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a href="skype:live:.cid.7cc4e6ff3f015417?chat"
                      ><i class="fa-brands fa-skype"></i>
                      <div class="id">
                        <h3>Skype</h3>
                        <p>pay2local</p>
                      </div>
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-wrapper">
              <form action="">
                <div class="parent-field">
                  <div class="form-field">
                    <label for="">Full Name</label>
                    <input type="text" />
                  </div>
                  <div class="form-field">
                    <label for="">Email</label>
                    <input type="text" />
                  </div>
                </div>
                <div class="form-field">
                  <label for="">Phone Number</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label for="">Country</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label for="">Message</label>
                  <textarea name="" id="" cols="30" rows="10"></textarea>
                </div>
                <div class="form-btn-field">
                  <button class="btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-home-footer></app-home-footer>

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-home-header",
  templateUrl: "./home-header.component.html",
  styleUrl: "./home-header.component.css",
})
export class HomeHeaderComponent {
  activeClass: string = "";

  constructor(
    private el: ElementRef,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  navigateToServices() {
    this.router.navigate(["/hm-services"]);
  }

  navigateToContact() {
    this.router.navigate(["/contact-us"]);
  }

  navigateToFeatures() {
    this.router.navigate(["/features"]);
  }

  navigateToOurStory() {
    this.router.navigate(["/our-story"]);
  }

  navigateToHome() {
    this.router.navigate(["/"]);
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const offset = this.el.nativeElement.getBoundingClientRect().top;
    if (offset < 0) {
      this.activeClass = "active";
    } else {
      this.activeClass = "";
    }
  }

  activeId: string | null = null;

  isClassAdded: boolean = false;

  showMenu() {
    this.isClassAdded = !this.isClassAdded;
  }

  ngOnInit(): void {
    // Scroll to the top when navigating back to the home or contact-us page
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd &&
            (this.router.url === "/" ||
              this.router.url === "/contact-us" ||
              this.router.url === "/hm-services" ||
              this.router.url === "/features" ||
              this.router.url === "/our-story")
        )
      )
      .subscribe(() => {
        window.scrollTo({
          top: 0,
          behavior: "auto", // No smooth scrolling when resetting to top
        });
      });
  }

  scrollToSection(sectionId: string): void {
    // Redirect to the home page with the sectionId as a fragment
    this.router.navigate(["/"], { fragment: sectionId }).then(() => {
      // Use setTimeout to ensure the page is loaded before scrolling
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          const offset = 60; // Offset by 60px
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const offsetPosition = elementRect - bodyRect - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth", // Smooth scrolling
          });
        }
      }, 100); // Adjust timeout if necessary
    });
  }

  ngAfterViewInit(): void {
    const sections = document.querySelectorAll("section");
    const options = {
      root: null, // viewport
      threshold: 0.6, // 60% of the section is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.activeId = entry.target.id;
        }
      });
    }, options);

    sections.forEach((section) => observer.observe(section));
  }
}

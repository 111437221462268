<div class="features-menu">
  <app-home-header></app-home-header>
</div>
<div class="features-page">
  <section class="features-banner">
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="content">
              <h1>
                Enhance Your Payment Experience with Seamless, Secure, and
                Supportive Solutions
              </h1>
              <p>
                Our platform offers a user-friendly interface, instant payments,
                and 24/7 support. Enjoy secure transactions, explore local
                businesses, and benefit from loyalty programs. Gain insights
                into your transactions with our analytics and reporting tools.
                Simplified, secure, and designed for you!
              </p>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="image">
              <img src="assets/home/images/features-banner.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features">
    <div class="container">
      <h2>Features</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-solid fa-desktop"></i>
            </div>
            <h3>User- Friendly Interface</h3>
            <p>
              Our payment forms are extremely user-friendly, ensuring a seamless
              navigation experience on both the app and website.
            </p>
            <p>
              The interface is designed to be intuitive and easy to use for all
              users.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-regular fa-clock"></i>
            </div>
            <h3>24X7 Assistance</h3>

            <p>
              Our dedicated support team is available round the clock to assist
              you with any queries or issues you may have.
            </p>
            <p>
              Whether you need help with a transaction or have a general
              inquiry, we are here to support you anytime, day or night.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-solid fa-bolt"></i>
            </div>
            <h3>Instant Payments</h3>
            <p>
              Enjoy the convenience of real-time processing for every
              transaction you make.
            </p>
            <p>
              With instant payments, you can quickly and securely complete your
              transactions without any delays.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-regular fa-building"></i>
            </div>
            <h3>Local Business Directory</h3>
            <p>
              Explore and support local businesses in your community through our
              comprehensive directory.
            </p>
            <p>
              Discover new and exciting businesses in your area and help
              contribute to the local economy.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-brands fa-buffer"></i>
            </div>
            <h3>Loyalty Programs</h3>
            <p>
              Take advantage of our loyalty programs to earn rewards and access
              exclusive promotional offers.
            </p>
            <p>
              We will have different cashback offers for our users along with
              other lucrative offers.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-solid fa-shield-halved"></i>
            </div>
            <h3>Secure Transactions</h3>
            <p>
              Rest assured that your transactions are safe and secure with our
              advanced encryption and fraud protection measures.
            </p>
            <p>
              We prioritize the security of your financial information to ensure
              a worry-free payment experience.
            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6">
          <div class="wrapper">
            <div class="icon">
              <i class="fa-solid fa-chart-pie"></i>
            </div>
            <h3>Analytics and Reporting</h3>
            <p>
              Gain valuable insights into transaction trends and user behavior
              through our analytics and reporting tools.
            </p>
            <p>
              Understand your customers better and make informed decisions to
              enhance your business strategies based on data-driven insights.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-home-footer></app-home-footer>

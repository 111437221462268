<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
  href="https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
  rel="stylesheet"
/>
<link
  rel="stylesheet"
  type="text/css"
  href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick.css"
/>
<link
  rel="stylesheet"
  type="text/css"
  href="https://cdn.jsdelivr.net/npm/slick-carousel/slick/slick-theme.css"
/>

<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
  integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>

<nav class="home-nav" [ngClass]="activeClass" id="nav">
  <div class="tagline">
    <h4>Affordable, Secure, and Convenient Payment Solutions</h4>
  </div>
  <div class="container-fluid">
    <div class="home-header" [class.show]="isClassAdded">
      <div class="ham_menu" (click)="showMenu()" [class.show]="isClassAdded">
        <i class="fa-solid fa-bars"></i>
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div class="nav-menu">
        <ul>
          <li class="menu-item">
            <a
              class="nav-link"
              href="javascript:void(0)"
              [ngClass]="{ active: activeId === 'about' }"
              (click)="scrollToSection('about')"
              (click)="navigateToHome()"
              >About</a
            >
          </li>
          <li class="menu-item">
            <a class="nav-link features" (click)="navigateToFeatures()"
              >Features</a
            >
          </li>
          <li class="menu-item">
            <a class="nav-link services" (click)="navigateToServices()"
              >Services
            </a>
          </li>
          <li class="menu-item">
            <a class="nav-link our-story" (click)="navigateToOurStory()"
              >Our Story</a
            >
          </li>
          <!-- <li class="menu-item">
            <a
              class="nav-link"
              [ngClass]="{ active: activeId === 'Our_Approach' }"
              href="javascript:void(0)"
              (click)="scrollToSection('Our_Approach')"
              >Our Approach</a
            >
          </li> -->
          <li class="menu-item">
            <!-- <a
                class="nav-link"
                [ngClass]="{ active: activeId === 'become_a_partner' }"
                href="javascript:void(0)"
                (click)="scrollToSection('become_a_partner')"
                >Contact Us</a
              > -->
            <a class="nav-link contact" (click)="navigateToContact()"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>

      <div class="nav_logo">
        <a (click)="navigateToHome()">
          <img class="logo-img" src="assets/home/images/Pay2Local.png" alt="" />
        </a>
      </div>
      <div class="select_lang">
        <img src="assets/home/images/Globe.svg" alt="" />
      </div>
      <!-- <div class="contact">
          <div class="login-btn">
            <a href="#become_a_partner">Contact Us</a>
          </div>
        </div> -->

      <div class="ham-overlay" (click)="showMenu()"></div>
    </div>
  </div>
</nav>
